exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-App__app--2iKXm {\n  display: flex;\n  height: 100%;\n  background-color: #202c33;\n  font-family: roboto;\n  font-size: 15px;\n}\n.src-App__app--2iKXm .src-App__content--c2W3_ {\n  flex: 1;\n  color: #464646;\n  padding: 25px;\n  background-color: #f9f9f9;\n  border-left: 2px solid #434e4e;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n.src-App__app--2iKXm .src-App__content--c2W3_ .src-App__container--3qxJc {\n  height: 100%;\n  width: 100%;\n}\n.src-App__app--2iKXm .src-App__sideBar--Xne_v {\n  flex-shrink: 0;\n}\n\nhtml, body, body > div {\n  height: 100%;\n  margin: 0;\n}\n\nbody {\n  font-family: \"-apple-system\", \"system-ui\", \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", \"sans-serif\";\n  font-size: 1rem;\n  font-weight: normal;\n  line-height: 1.5;\n}", ""]);

// Exports
exports.locals = {
	"app": "src-App__app--2iKXm",
	"content": "src-App__content--c2W3_",
	"container": "src-App__container--3qxJc",
	"sideBar": "src-App__sideBar--Xne_v"
};