exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-containers-Admin-styles__paper--1kG8A {\n  padding: 15px;\n  overflow-x: auto;\n}\n\n.src-containers-Admin-styles__searchbar--fmQw3 {\n  margin-top: 70px;\n  margin-bottom: 20px;\n}\n\n.src-containers-Admin-styles__results--26PbL {\n  color: #616060 !important;\n  margin-left: 15px !important;\n  text-decoration: bold !important;\n}\n\n.src-containers-Admin-styles__create--tR1D5 {\n  display: inline-block;\n  float: right;\n  margin: 70px 50px;\n}\n\n.src-containers-Admin-styles__icon-link--2df7g {\n  padding: 0 6px;\n  cursor: pointer;\n}", ""]);

// Exports
exports.locals = {
	"paper": "src-containers-Admin-styles__paper--1kG8A",
	"searchbar": "src-containers-Admin-styles__searchbar--fmQw3",
	"results": "src-containers-Admin-styles__results--26PbL",
	"create": "src-containers-Admin-styles__create--tR1D5",
	"icon-link": "src-containers-Admin-styles__icon-link--2df7g",
	"iconLink": "src-containers-Admin-styles__icon-link--2df7g"
};