exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-containers-Login-styles__login--3cGKc {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.src-containers-Login-styles__main--3QRF7 {\n  display: flex;\n  margin-left: 30px;\n  margin-right: 30px;\n}\n\n.src-containers-Login-styles__paper--1Tu8_ {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px 30px 30px;\n}\n\n.src-containers-Login-styles__avatar--1pt4M {\n  margin: 10px;\n  background-color: black;\n}\n\n.src-containers-Login-styles__form--2H8Ns {\n  width: 100%;\n  margin-top: 10px;\n}\n\n.src-containers-Login-styles__submit--BBpi0 {\n  margin-top: 20px;\n  text-align: center;\n}\n\n.src-containers-Login-styles__input--2dYtE {\n  padding-left: 6px;\n  padding-right: 6px;\n}\n\n.src-containers-Login-styles__error--1dHen {\n  color: red;\n}\n\n.src-containers-Login-styles__progress--1rzXR {\n  margin-top: 10px;\n}", ""]);

// Exports
exports.locals = {
	"login": "src-containers-Login-styles__login--3cGKc",
	"main": "src-containers-Login-styles__main--3QRF7",
	"paper": "src-containers-Login-styles__paper--1Tu8_",
	"avatar": "src-containers-Login-styles__avatar--1pt4M",
	"form": "src-containers-Login-styles__form--2H8Ns",
	"submit": "src-containers-Login-styles__submit--BBpi0",
	"input": "src-containers-Login-styles__input--2dYtE",
	"error": "src-containers-Login-styles__error--1dHen",
	"progress": "src-containers-Login-styles__progress--1rzXR"
};