exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-Alert-styles__alert--3JbuN {\n  margin: 8px;\n  flex-grow: 1;\n  color: #fff;\n  display: flex;\n  padding: 6px 24px;\n  flex-wrap: wrap;\n  align-items: center;\n}\n\n.src-components-Alert-styles__message--26aYl {\n  display: flex;\n  align-items: center;\n}\n\n.src-components-Alert-styles__icon--11MJa {\n  font-size: 20;\n}\n\n.src-components-Alert-styles__iconVariant--Jpvoz {\n  opacity: 0.9;\n  margin-right: 15px;\n}\n\n.src-components-Alert-styles__success--29hE9 {\n  background-color: #43a047 !important;\n}\n\n.src-components-Alert-styles__error--3kMT6 {\n  background-color: #d32f2f !important;\n}\n\n.src-components-Alert-styles__info--36HI3 {\n  background-color: #1976d2 !important;\n}\n\n.src-components-Alert-styles__warning--1k_3F {\n  background-color: #ffa000 !important;\n}", ""]);

// Exports
exports.locals = {
	"alert": "src-components-Alert-styles__alert--3JbuN",
	"message": "src-components-Alert-styles__message--26aYl",
	"icon": "src-components-Alert-styles__icon--11MJa",
	"iconVariant": "src-components-Alert-styles__iconVariant--Jpvoz",
	"success": "src-components-Alert-styles__success--29hE9",
	"error": "src-components-Alert-styles__error--3kMT6",
	"info": "src-components-Alert-styles__info--36HI3",
	"warning": "src-components-Alert-styles__warning--1k_3F"
};